@import "~normalize-scss";
@import "~react-redux-toastr/lib/css/react-redux-toastr.min.css";
@import "variables";

@font-face {
  font-family: 'Roboto';
  src: url("../assets/fonts/Roboto-Thin.woff2") format('woff2');
  font-weight: 100;
}

@font-face {
  font-family: 'Roboto';
  src: url("../assets/fonts/Roboto-Light.woff2") format('woff2');
  font-weight: 300;
}

@font-face {
  font-family: 'Roboto';
  src: url("../assets/fonts/Roboto-Regular.woff2") format('woff2');
  font-weight: 400;
}

@font-face {
  font-family: 'Roboto';
  src: url("../assets/fonts/Roboto-Medium.woff2") format('woff2');
  font-weight: 500;
}

@font-face {
  font-family: 'Roboto';
  src: url("../assets/fonts/Roboto-Bold.woff2") format('woff2');
  font-weight: 700;
}

* {
  box-sizing: border-box;
  outline: none;
}

html,
body,
#root {
  height: 100%;
  min-width: 1280px;
}

body {
  margin: 0;
  font-family: 'Roboto', monospace, sans-serif;
  font-weight: normal;
  font-size: 14px;
}

.MuiButton-root {
  font-family: 'Roboto', monospace, sans-serif;
  font-size: 14px;
}

a {
  font-family: 'Roboto', monospace, sans-serif;
}

main {
  height: calc(100% - 64px);
  padding: 0 16px;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-track {
  background: $lightgrey;
}
::-webkit-scrollbar-thumb {
  background: $grey;
}
::-webkit-scrollbar-thumb:hover {
  background: $darkgrey;
}
