.tender-task-page {
  height: 100%;
}

.tender-task-header {
  margin-bottom: 20px;
}

.tender-task-table {
  max-height: calc(100% - 75px);
}

.icon-column {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  svg {
    margin-right: 5px;
  }
}