.nav-list-item {
  margin-right: 17px;
}

.nav-list-link {
  text-decoration: none;
  &.active {
    pointer-events: none;
    .MuiButton-label {
      &:before {
        opacity: 1;
      }
    }
  }
}

.nav-list-button {
  &.MuiButtonBase-root {
    position: relative;
    text-transform: none;
    padding: 8px;
    padding-top: 16px;
    padding-bottom: 0;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.3px;
    color: rgb(76, 94, 207);
    &:hover {
      background-color: transparent;
    }
  }
  .MuiButton-label {
    position: relative;
    display: inline-block;
    width: auto;
    padding-bottom: 16px;
    &:before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 3px;
      background-color: rgb(76, 94, 207);
      opacity: 0;
    }
  }
}