@import "src/styles/variables";

.auth-page {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: $main-bg-color;
  &__contains {
    width: 345px;
  }
  &__logo {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 35px;
  }
  &__logo-icon {
    margin-right: 20px;
  }
  &__logo-text {
    color: $white;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: bold;
  }
}

.security {
  padding: 25px 35px;
  background-color: $white;
  &__title {
    color: #05082E;
    margin: 0 0 20px 0;
    padding-bottom: 20px;
    font-size: 24px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }
  .MuiFormControl-root {
    margin: 10px 0;
  }
  .MuiFormControlLabel-root {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  &__actions {
    margin: 10px 0;
    &.center {
      text-align: center;
    }
    &.right {
      text-align: right;
    }
    button {
      text-transform: none;
    }
  }
  &__links {
    padding-top: 20px;
    display: flex;
    justify-content: space-between;
    &.center {
      justify-content: center;
    }
  }
}