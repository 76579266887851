.headline {
  display: flex;
  align-items: flex-start;
  border-bottom: 1px solid rgb(226, 228, 233);
  min-height: 38px;
}

.nav-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
}