// COLORS
$black: #000000;
$white: #ffffff;
$lightgrey: #f9f9fb;
$grey: #e7e7e7;
$darkgrey: #c0c0c0;
$main-bg-color: #404fae;
$tab-underline-color: #4c5ecf;
$primary-btn-bg-color: #1b55c5;
$secondary-btn-text-color: #616284;
$warning-btn-bg-color: #c93a3a;
$side-menu-link-bg-active: rgba(235,236,240,.1);
$side-menu-link-bg-hovered: rgba(235,236,240,.05);
$side-menu-link-inactive: #dadada;

$weakTextColor: #616284;
$mainTextColor: #05082e;
$errorTextColor: #f44336;